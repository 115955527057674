<template>
   <main id="individual-account">
     <header class="user-account-header">
       <h2 class="user-account-title">{{ getString(strings.Individual_Account) }}</h2>
     </header>
     <article class="user-account-container">
       <section class="user-account-item">
         <div class="account-number-row">
           <span class="account-num">{{myMBeans}}</span>
           <img src="../../assets/img/user/account/mbean.png" alt="" width="18px" style="margin:0 8px 0 8px;">
         </div>
         <p class="account-label">
          <!-- 当前M豆总数 -->
          {{ getString(strings.Current_Total_MBeans) }}
         </p>
         <div class="account-operate" @click="ShowMBeansShoppingModel">
          <!-- 充值 -->
          {{ getString(strings.Recharge) }}
         </div>
       </section>
       <section class="user-account-item user-account-profit">
         <div class="profit-details" @click.prevent="seeExplain">
            <span class="iconfont1 iconfont1-slider">&#xe613;</span>
         </div>
         <div class="account-number-row">
           <span class="account-num">{{myMGold}}</span>
           <img src="../../assets/img/user/account/mcoin.png" alt="" width="19px" style="margin:0 8px 0 8px;">
         </div>
         <p class="account-label">
          <!-- 当前收益 -->
          {{ getString(strings.Current_Revenue) }}
         </p>
         <button class="account-operate withdrawal-button" @click="showWithdrawalModal" >
          <!-- 提现 -->
          {{ getString(strings.Withdrawal) }}
         </button>
         <!-- 提现状态 -->
         <div v-if="status" class="withdrawal-status" :title="status">
          <!-- {{ getString(strings.Withdrawal_Status) }}: -->
            {{status}}
         </div>
       </section>
     </article>
     <section class="withdrawal-bg" v-if="withdrawalModal">
      <div class="withdrawal-modal">
        <div class="withdrawal-title">
            <div class="withdrawal-name">
              <!-- 提现 -->
              {{ getString(strings.Withdrawal) }}
            </div>
            <div class="close-setting">
                <img class="close-button-img" src="../../assets/img/popupsImg/colse_new.svg"  @click.stop="hideWithdrawalModal()" />
            </div>
        </div>
        <div class="withdrawal-form">
          <div class="withdrawal-amount-box">
            <div class="withdrawal-amount-item">
              <div class="withdrawal-amount-left">
                <!-- 提现金额 -->
                <label class="withdrawal-amount-title" for="withdrawal-num">{{ getString(strings.Withdrawal_Amount) }} </label>
                <a-input oninput="value=value.replace(/[^\d.]/g,'');" @blur="maxWithdrawalNum"  v-model="withdrawalNum" id="withdrawal-num" prefix="￥" style="width: 100px;"/>
              </div>
              <!-- 全部 -->
              <div class="withdrawal-all" @click="withdrawalAll">
                {{ getString(strings.My_Mind_All) }} 
              </div>
            </div>
          </div>
          <div class="withdrawal-info-box">
            <div class="withdrawal-info-item">
                 <!-- 姓名 -->
                  <label class="withdrawal-info-title" for="account-name">{{ getString(strings.Contact_Us_Username) }} </label>
                  <a-input :placeholder="getString(strings.Bank_Card_Account_Name)" :maxLength="12" v-model="accountName" id="account-name" />
            </div>
            <div class="withdrawal-info-item">
                 <!-- 银行卡号 -->
                  <label class="withdrawal-info-title" for="bank-card">{{ getString(strings.Bank_Card_No) }} </label>
                  <a-input oninput="value=value.replace(/\D/g,'');" :placeholder="getString(strings.Please_Enter_Your_Bank_Card_No)" v-model="bankCard" id="bank-card" />
            </div>
            <div class="withdrawal-info-item">
                 <!-- 分行支行信息 -->
                  <label class="withdrawal-info-title" :title="getString(strings.Branch_Sub_Branch_Information)" for="branch-sub">{{ getString(strings.Branch_Sub_Branch_Information) }} </label>
                  <a-input :placeholder="getString(strings.Branch_Sub_Branch_Example)" v-model="branchSub" id="branch-sub" />
            </div>
          </div>
          <!-- 申请提现 -->
          <button class="withdrawal-btn" @click="withdrawalBtn">
            {{ getString(strings.Application_For_Withdrawal) }}
          </button>
        </div>
      </div>
     </section>

   </main>
</template>

<script>

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import {
 
} from "../../common/netWork/base_api";
import {
  postMemberMyAccount,
  postMemberWithdraw
} from '../../common/netWork/member_api';
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      strings,
      myMBeans: '--', //M豆
      myMGold: '--', //M金
      status: '', //M金
      withdrawal:false,//提现
      withdrawalModal:false,//提现弹框
      withdrawalNum:0,//提现金额
      accountName:'',//户名
      bankCard:'',//银行卡号
      branchSub:'',//分行支行信息

    };
  },
  computed:{
      inpWithdrawalNum:{
          get:function(){
              return this.withdrawalNum;
          
          },
          set:function(newValue){
              this.withdrawalNum=newValue.replace(/[^\d]/g,'');
          }
      }
  },
  components: {
     
  },
  mounted() {
    this.getAccount();

  },
  methods: {
    ...mapMutations([]),
    getString(i) {
      return getString(i);
    },
   
    // 获取我的账户
    getAccount() {
      postMemberMyAccount({}, (res) => {
        if (res) {
          this.myMBeans = res.mbeansNum;
          this.myMGold = res.mcoinsNum;
          this.withdrawal = res.withdrawable;
          this.status = res.status;
        }
      })
    }, 
    //显示购买M豆弹框
    ShowMBeansShoppingModel(){
      this.bus.$emit('shoppingModel',true);
    },
    //显示购提现弹框
    showWithdrawalModal(){
      this.withdrawalModal = true
    },
    hideWithdrawalModal(){
      this.withdrawalModal = false
    },
    maxWithdrawalNum(){
      this.withdrawalNum = parseFloat(this.withdrawalNum)>parseFloat(this.myMGold)?this.myMGold:this.withdrawalNum
    },
    // 全部提现
    withdrawalAll(){
      this.withdrawalNum = this.myMGold
    },
    // 申请提现
    withdrawalBtn(){
      if (this.withdrawalNum==0||this.withdrawalNum=="") {
        this.$message.error(getString(strings.Save_Img_Please_Input)+getString(strings.Withdrawal_Amount));
        return
      }else if (this.accountName=="") {
        this.$message.error(getString(strings.Bank_Card_Account_Name))
        return
      }else if (this.bankCard=="") {
        this.$message.error(getString(strings.Please_Enter_Your_Bank_Card_No))
        return
      }else if (this.branchSub=="") {
        this.$message.error(getString(strings.Save_Img_Please_Input)+getString(strings.Branch_Sub_Branch_Information));
        return
      }else if (!this.withdrawal) {
        // this.$message.error(getString(strings.Insufficient_Withdrawal_Amount));
        // return
      }
      let obj = {
        amount:parseInt(this.withdrawalNum),
        realName:this.accountName,
        cardNum:this.bankCard,
        bank:this.branchSub,
      }
      postMemberWithdraw(obj, (res) => {
        this.getAccount();
      }, (error) => {
        this.$message.error(error.desc)
      })
      this.hideWithdrawalModal()
      this.getAccount();
    },
    seeExplain(){
        const h = this.$createElement;
        this.$info({
            title: this.$getStringObj.getString(this.$Strings.Mind_Explain),
            content: h('div', {}, [
                h('p', this.$getStringObj.getString(this.$Strings.MGold_Introduction)),
                h('p', this.$getStringObj.getString(this.$Strings.MGold_Details)),
            ]),
            centered: true,
            okText:this.$getStringObj.getString(this.$Strings.Global_Ok),
            onOk() {
            },
        });
    },
  },
  watch:{
    "$store.state.showShopping"(newval, olval) {
      if(!newval){
        this.getAccount();
      }
    },
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "iconfont1";
  src: url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.eot");
  src: url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.svg#iconfont")
      format("svg");
}
.iconfont1 {
  font-family: "iconfont1" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconfont1-slider {
  font-size: 16px;
  margin-left: 18px;
  margin-right: 5px;
}
button[disabled] {
    cursor: not-allowed !important;
}
#individual-account{
  width: 100%;
  padding: 20px 30px;
  .user-account-header{
    width: 100%;
    .user-account-title{
      color: #333333;
      font-size: 16px;
    } 
  } 
  .user-account-container{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    .user-account-item{
      width: 334px;
      height: 176px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #EEEEEE;

      &:hover{
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      }
      .account-number-row{
        display: flex;
        align-items: center;
        .account-num{
          font-size: 30px;
          color: #000000;
        }
      }
      .account-label{
        font-size: 14px;
        color: #999999;
        margin: 10px 0 25px;
      }
      .account-operate{
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
      .withdrawal-button{
        outline: 0;
        border: 0;
        background: #FFFFFF;
        
      }
      .withdrawal-status{
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FF0000;
        position: relative;
        top: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .user-account-profit{
      margin-left: 30px;
      position: relative;
      .profit-details{
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        &:hover{
          color: #ff7354;
        }
      }
      
    }
  }
}
.withdrawal-bg{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  .withdrawal-modal{
    width: 380px;
    // height: 325px;
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .withdrawal-title{
      width: 100%;
      height: 38px;
      position: relative;
      padding: 6px 20px;
      background: #efefef;
    }
    .withdrawal-name{
      position: absolute;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .close-setting{
        position: relative;
        float: right;
    }
  
    .close-button-img{
        width:18px;
        cursor: pointer;
        opacity: 0.5;
    }
    .close-button-img:hover{
      opacity: 0.8;
    }
    .withdrawal-form{
      padding: 30px 12px 20px;
      .withdrawal-amount-box{
        width: 354px;
        height: 50px;
        background: #F5F5F5;
        padding: 10px;
        .withdrawal-amount-item{
          width: 100%;
          height: 30px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .withdrawal-amount-left{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .withdrawal-amount-title{
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
            }
            .ant-input-affix-wrapper{
              width: 100px;
              margin-left: 3px;
              font-size: 18px;
              color: #333333;
              font-weight: 500;
              /deep/.ant-input{
                border: 0;
                background: #F5F5F5;
                font-size: 18px;
                color: #333333;
                font-weight: 600;
              }
            }
          }
          .withdrawal-all{
            width: 50px;
            height: 24px;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
            border: 1px solid #999999;
            border-radius: 4px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
      .withdrawal-info-box{
        width: 354px;
        // height: 125px;
        background: #F5F5F5;
        padding: 2px 10px 10px;
        margin-top: 10px;
        .withdrawal-info-item{
          width: 100%;
          height: 35px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .withdrawal-info-title{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .ant-input{
            width: 200px;
            border: 0;
            padding: 0;
            background: #F5F5F5;
            font-size: 14px;
            color: #333333;
            font-weight: 500;
            text-align: right;
          }
        }
      }
      .withdrawal-btn{
        width: 354px;
        height: 36px;
        outline: 0;
        border: 0;
        background: #FF765E;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 30px;
      }
    }
  }
}
</style>